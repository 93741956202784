import "./style.css";
import "./main_css.css";

import {
  PlaneBufferGeometry,
  Scene,
  TextureLoader,
  sRGBEncoding,
  MeshBasicMaterial,
  ShaderMaterial,
  Color,
  BufferGeometry,
  BufferAttribute,
  Points,
  PerspectiveCamera,
  WebGLRenderer,
  Clock,
  Mesh,
  LoadingManager,
} from "three";
import { gsap } from "gsap";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import portalVertexShader from "./shaders/portal/vertex.glsl";
import portalFragmentShader from "./shaders/portal/fragment.glsl";
import starsVertexShader from "./shaders/stars/vertex.glsl";
import starsFragmentShader from "./shaders/stars/fragment.glsl";

const canvas = document.querySelector("canvas.webgl");
const scene = new Scene();

const loadingManager = new LoadingManager(() => {
  gsap.to(planeMaterial.uniforms.uAlpha, { duration: 3, value: 0 });
  scene.remove(PlaneMesh);

  if (document.getElementById("roller")) {
    document.getElementById("roller").style.display = "none";
    document.getElementById("contains").style.display = "flex";
  }
});

const gltfLoader = new GLTFLoader(loadingManager);
const textureLoader = new TextureLoader(loadingManager);
const planeGeometry = new PlaneBufferGeometry(2, 2, 1, 1);
const planeMaterial = new ShaderMaterial({
  uniforms: {
    uAlpha: {
      value: 1,
    },
  },
  transparent: true,
  vertexShader: `
    void main(){
        gl_Position = vec4(position,1.0);
    }
        
    `,
  fragmentShader: `
    uniform float uAlpha;
    void main(){
        gl_FragColor = vec4(0.0,0.0,0.0,uAlpha);
    }
    `,
});
const PlaneMesh = new Mesh(planeGeometry, planeMaterial);

scene.add(PlaneMesh);

const bakedTexture = textureLoader.load("final.jpg");
bakedTexture.flipY = false;
bakedTexture.encoding = sRGBEncoding;

const bakedMaterial = new MeshBasicMaterial({ map: bakedTexture });
const boardLightMaterial = new MeshBasicMaterial({ color: 0xff6384 });
const smallCircleMaterial = new ShaderMaterial({
  uniforms: {
    uTime: {
      value: 0,
    },
  },
  transparent: true,
  vertexShader: `
    uniform float uTime;

    void main()
    {
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
        modelPosition.x += sin(uTime * 2.0) ;
        modelPosition.z +=  cos(uTime) ;
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectionPosition = projectionMatrix * viewPosition;
    
        gl_Position = projectionPosition;

    }
    `,
  fragmentShader: `
    void main(){
        gl_FragColor = vec4(0.255, 0.0, 0.127, 1.0);
    }
    `,
});
const mediumCircleMaterial = new ShaderMaterial({
  uniforms: {
    uTime: {
      value: 0,
    },
  },
  transparent: true,
  vertexShader: `
    uniform float uTime;

    void main()
    {
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
        modelPosition.x += sin(uTime) ;
        modelPosition.z +=  cos(uTime) ;
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectionPosition = projectionMatrix * viewPosition;
    
        gl_Position = projectionPosition;

    }
    `,
  fragmentShader: `
    void main(){
        gl_FragColor = vec4(0.136, 0.216, 0.192, 1.0);
    }
    `,
});
const bigCircleMaterial = new ShaderMaterial({
  uniforms: {
    uTime: {
      value: 0,
    },
  },
  transparent: true,
  vertexShader: `
    uniform float uTime;

    void main()
    {
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
        modelPosition.z += sin(uTime) ;
        modelPosition.y +=  cos(uTime) ;
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectionPosition = projectionMatrix * viewPosition;
    
        gl_Position = projectionPosition;
    }
    `,
  fragmentShader: `
    void main(){
        gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
    }
    `,
});
const portalLightMaterial = new ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
    uColorStart: { value: new Color(0x3c68af) },
    uColorEnd: { value: new Color(0x000000) },
  },
  vertexShader: portalVertexShader,
  fragmentShader: portalFragmentShader,
});

gltfLoader.load("tes.glb", (gltf) => {
  scene.add(gltf.scene);
  gltf.scene.children.find((child) => child.name == "mergebaked").material =
    bakedMaterial;
  gltf.scene.children.find((child) => child.name == "smalllight").material =
    smallCircleMaterial;
  gltf.scene.children.find((child) => child.name == "mediumlight").material =
    mediumCircleMaterial;
  gltf.scene.children.find((child) => child.name == "biglight").material =
    bigCircleMaterial;
  gltf.scene.children.find((child) => child.name == "boardlight").material =
    boardLightMaterial;
  gltf.scene.children.find((child) => child.name == "insidecircle").material =
    portalLightMaterial;
  gltf.scene.children.find((child) => child.name == "outsidecircle").material =
    portalLightMaterial;
});

const starsGeometry = new BufferGeometry();
const positionArray = new Float32Array(750);

for (let i = 0; i < 250; i++) {
  positionArray[i * 3 + 0] = (Math.random() - 0.5) * 300;
  positionArray[i * 3 + 1] = (Math.random() - 0.5) * 400;
  positionArray[i * 3 + 2] = (Math.random() - 0.5) * 300;
}
starsGeometry.setAttribute("position", new BufferAttribute(positionArray, 3));

const starsMaterial = new ShaderMaterial({
  uniforms: {
    uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
  },
  vertexShader: starsVertexShader,
  fragmentShader: starsFragmentShader,
  transparent: true,
  depthWrite: false,
});

const stars = new Points(starsGeometry, starsMaterial);
scene.add(stars);

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const camera = new PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 300);
camera.position.x = 10.31;
camera.position.y = 2.49;
camera.position.z = -12.9;
scene.add(camera);

const renderer = new WebGLRenderer({
  canvas: canvas,
  antialias: true,
});

renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.outputEncoding = sRGBEncoding;

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  // Update star size
  starsMaterial.uniforms.uPixelRatio.value = Math.min(
    window.devicePixelRatio,
    2
  );
});

const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.minDistance = 12;
controls.maxDistance = 45;

const clock = new Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  portalLightMaterial.uniforms.uTime.value = elapsedTime;

  smallCircleMaterial.uniforms.uTime.value = elapsedTime;

  bigCircleMaterial.uniforms.uTime.value = elapsedTime;

  mediumCircleMaterial.uniforms.uTime.value = elapsedTime;

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

document.addEventListener("DOMContentLoaded", () => {
  const switchy = document.querySelector(".switch");
  switchy.addEventListener("click", () => {
    let c = document.querySelector(".container-content");
    if (c.style.zIndex >= 1) {
      c.style.zIndex = -1;
    } else {
      c.style.zIndex = 2;
    }
  });
});
